/* Add this CSS in your stylesheets or as a styled-component */

.chat-container {
  display: flex;
  flex-direction: column;
  height: 98vh; /* 70% of the viewport height */
  width: 80%; /* 80% of the parent container width */
  margin: auto;
  background-color: black;
  color: white;
  margin-bottom:4px;
}

.chat-history {
  flex: 1; /* Expand to fill the available space */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 10px;
  background-color: #282c34;
  color: #a461e0;
  font-family: Söhne, ui-sans-serif, system-ui, -apple-system, "Segoe UI", Roboto, Ubuntu;
  font-size:20px;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  padding: 20px;
  margin-bottom:30px;
  box-shadow: inset 0 0 10px #000000;
}

.gpt-input-textarea {
  @apply w-full text-sm p-1 sm:p-1 rounded-md leading-tight;
  min-height: 30px; /* Reduce input height for mobile */
  height: 40px; /* Ensure it doesn't expand too much */
}

@media (min-width: 640px) {
  .gpt-input-textarea {
    min-height: 80px;
    height: 100px;
  }
}

.chat-input {
  @apply flex items-center p-1 sm:p-4;
  height: 50px; /* Reduce height for mobile */
}

/* Increase height for larger screens */
@media (min-width: 640px) {
  .chat-input {
    height: 100px;
  }
}


.gpt-title {
  @apply text-lg sm:text-lg md:text-2xl font-bold text-white leading-tight;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Ensures text is limited to 1 line */
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; /* Truncate text with '...' if it overflows */
  max-width: 100%; /* Prevents it from extending beyond the container */
}


.run-button {
  width: 100px; /* Adjust the width as needed */
  margin-top: 130px;
  margin-left:10px;
}

.chat-input {
  @apply flex p-2 sm:p-0;
  display: flex;
  justify-content: space-between; /* Align items to the left and right edges */
}

/* Style for user messages */
.chat-message-assistant {
  @apply text-sm sm:text-base md:text-lg;
  background-color: #202021; /* Purple background for user messages */
  color:white;
  padding-bottom:30px;
  padding-top:10px;
  padding-right:8px;
  padding-left:24px;
  border-radius: 16px;
  margin-bottom:18px;
  margin-right:40%;
}

/* Style for assistant messages */
.chat-message-user {
  @apply text-sm sm:text-base md:text-lg;
  background-color: #5D5D5D; /* Purple background for user messages */
  color:white;
  border-radius: 16px;
  padding-bottom:30px;
  padding-top:10px;
  padding-right:18px;
  padding-left:24px;
  margin-bottom:12px;
  margin-left:40%;
}

/* ... Your existing CSS ... */

.gpt__icon {
  position: relative;
  top: 0;
  left: 0;
  width: 95px; /* adjust as needed */
  height: 75px; /* adjust as needed */
  padding-left: 26px;
  padding-top: 30px;
  margin-top:40px;
  padding-bottom: 20px;
  padding-right: 12px;
  display: flex;
  align-items: center; /* Vertically center the icon */
}

/* ... Your existing CSS ... */


.header-container {
    display: flex; /* Use flex layout */
    align-items: center; /* Vertically center elements */
    margin:12 px;
}

/* styles.css or your stylesheet */
.custom-code {
  background-color: black; /* Set your desired background color */
  border: 1px solid #ddd; /* Add borders or other styles as needed */
  padding: 10px;
  margin: 10px 0;
  overflow-x: auto; /* Enable horizontal scrolling for long code lines */
}

.custom-code-text {
  color: #a461e0; /* Set your desired font color for code text */
}

/* Style the disabled textarea */
.gpt-input-textarea:disabled {
  color: #aaa; /* Grey out the text */
  background-color: #f4f4f4; /* Light grey background */
  cursor: not-allowed; /* Show a "not-allowed" cursor */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.action-container {
  width: 240px; /* Set to the desired width of the button and dropdown */
  margin: 0 auto; /* Center the container */
  padding:10px;
  display: flex;
  flex-direction: column;
}

.run-button, .dropdown-container select {
  width: 100%; /* Take up full width of the container */
  margin-bottom:20px;
}

.dropdown-container {
  height:10px;
  margin-bottom: 10px; /* Optional: Add some space between the dropdown and the button */
}

.nodrag-dropdown{
  
}


.run-button-custom {
  background: linear-gradient(to right, #F81E1E, #f8801e) !important;
  width:100px;
}